import {
  AfterContentInit, AfterViewInit,
  Component,
  ContentChildren, EventEmitter,
  Input, OnInit, Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import {CarouselImageComponent} from "./carousel-image/carousel-image.component";
import Flickity from "flickity";
import {delay} from "rxjs/operators";

@Component({
  selector: 'iris-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CarouselComponent implements AfterContentInit, OnInit, AfterViewInit {
  @Input() height = '100px';
  @Input() gap = '10px';
  @Input() isOutsideArrow = false;
  @Input() carouselId: string;
  @Input() arrowColor: 'black' | 'white' = 'black';
  @Input() arrowSize: 'big'| 'small' = 'small';
  @Input() autoPlay:boolean|number = false;
  @Output() loaded = new EventEmitter<void>();


  @ContentChildren(CarouselImageComponent) inputImages: QueryList<CarouselImageComponent>;

  public images: CarouselImageComponent[]=[];

  constructor() {
  }


  public ngAfterContentInit(): void {
    if (this.inputImages) {
      this.images =  this.inputImages.toArray();

      this.inputImages.changes.pipe(delay(0)).subscribe(() => {
        this.images =  this.inputImages.toArray();
      });

    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    setTimeout(
      () => {
        new Flickity(`.${this.carouselId}`,
          {
            lazyLoad: 3,
            imagesLoaded: true,
            freeScroll: false,
            setGallerySize: false,
            wrapAround: true,
            autoPlay: this.autoPlay,
            pageDots: false,
            draggable: true,
            arrowShape: {
              x0: 43,
              x1: 63, y1: 35,
              x2: 65, y2: 35,
              x3: 45
            }
          }
        );
        this.loaded.emit();
      }
      , 2500
    );

  }

}
