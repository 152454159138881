import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ImageDetailsResponse} from "../../../features/image-upload/models/image-details.response";
import {ImageDetailsService} from "../../services/imageDetails/image-details.service";
import {ToastrService} from "ngx-toastr";
import {animate, style, transition, trigger} from "@angular/animations";
import {POINT_OF_INTEREST} from "../../models/point-of-interest";
import {Observable, of} from 'rxjs';
import {CommentsService} from "../../services/imageDetails/comments.service";
import {CommentResponse} from "../../models/comment.response";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthorizationService} from "../../services/authorization/authorization.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TitleTagService} from "../../services/tag/title-tag.service";

@Component({
  selector: "iris-lightbox-modal",
  templateUrl: "./lightbox-modal.component.html",
  styleUrls: ["./lightbox-modal.component.scss"],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({opacity: 0}),
        animate('200ms', style({opacity: 1})),
      ]),
      transition(':leave', [
        animate('200ms', style({opacity: 0}))
      ])
    ])
  ]
})
export class LightboxModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  isLoggedIn$: Observable<boolean>;
  isInfoVisible = false;
  isCommentsVisible = false;
  isImageUploading = false;
  allImages: ImageDetailsResponse[];
  currentImage: ImageDetailsResponse;
  comments$: Observable<CommentResponse[]>;
  form: FormGroup;
  locations = POINT_OF_INTEREST;

  constructor(
    public dialogRef: MatDialogRef<LightboxModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageId: string, userId: string },
    private imageDetailsService: ImageDetailsService,
    private commentsService: CommentsService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private toastr: ToastrService,
    private titleTagService: TitleTagService

  ) {
  }

  ngOnInit(): void {

    // set current image from input
    this.imageDetailsService.findOne(this.data.imageId).subscribe(
      // on success load image
      image => {
        this.currentImage = {...image};
        this.setMetaTagsForSocialMedia(image);
        },
      // on error redirect to home
      () => {
      this.closeDialog();
      this.router.navigate(['/photographers']);
    });

    // get all images of carousel
    this.imageDetailsService.findAllByUser((this.data.userId).toString()).subscribe(
      // on success load image
      images => {
        if(images.length == 0 || ( images.length > 0 && images[0].userId != this.currentImage.userId)){
          this.closeDialog();
          this.router.navigate(['/photographers']);
        }else{
          this.allImages = images;
        }
      },
      // on error redirect to home
      () => {
        this.closeDialog();
        this.router.navigate(['/photographers']);
      }
    );
    // build comments form
    this.form = this.fb.group({
      comment: [""]
    });
    this.comments$ = this.loadComments(this.data.imageId);
    this.isLoggedIn$ = this.authorizationService.isLoggedIn$;


  }

  toggleInfo() {
    this.isInfoVisible = !this.isInfoVisible;
    this.isCommentsVisible = false;
  }

  toggleComments() {
    this.isCommentsVisible = !this.isCommentsVisible;
    this.isInfoVisible = false;
  }

  nextImage() {
    // get current image index from all images array
    const currentImageIndex = this.allImages.findIndex(imageDetails => imageDetails.id === this.currentImage.id);

    // if it is the last image go to the first one
    if (currentImageIndex + 1 === this.allImages.length) {
      this.currentImage = {...this.allImages[0]};
    } else {
      // go to the next index
      this.currentImage = {...this.allImages[currentImageIndex + 1]};
    }
    // load comments of next image
    this.comments$ = this.loadComments(this.currentImage.id);

    // update query params
    this.updateQueryParams(this.currentImage.id);

    this.setMetaTagsForSocialMedia(this.currentImage);

  }

  previousImage() {
    // get current image index from all images array
    const currentImageIndex = this.allImages.findIndex(imageDetails => imageDetails.id === this.currentImage.id);

    // if it is the first image go to the last one
    if (currentImageIndex === 0) {
      this.currentImage = {...this.allImages[this.allImages.length - 1]};
    } else {
      // go to the previous index
      this.currentImage = {...this.allImages[currentImageIndex - 1]};
    }
    // load comments of previous image
    this.comments$ = this.loadComments(this.currentImage.id);

    // update query params
    this.updateQueryParams(this.currentImage.id);

    this.setMetaTagsForSocialMedia(this.currentImage);

  }

  like(id) {
    this.imageDetailsService
      .like(id)
      .subscribe(
        imageDetails => this.currentImage = imageDetails);
  }

  loadComments(id): Observable<CommentResponse[]> {
    return this.commentsService.findAllByImage(id)
  }

  commentOnImage(imageId: string, form: FormGroup) {
    // show spinner
    this.isImageUploading = true;

    // display success message
    const onSuccess = () => {
      form.controls['comment'].setValue('');
      this.isImageUploading = false;
      this.comments$ = this.loadComments(this.currentImage.id);
      this.currentImage.publicComments++;
    };
    //display error message
    const onError = response => {
      if (response.error && response.error.message) {
        this.toastr.warning(response.error.message);
      } else if (response.status !== 200) {
        this.toastr.warning("Service unavailable");
      }
      this.isImageUploading = false;
    };

    this.commentsService
      .comment(form.value.comment, imageId)
      .subscribe(onSuccess, onError);
  }

  shareImage(){
    const dummy = document.createElement('input'),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.toastr.info("Url copied to clipboard");
  }
  closeDialog() {
    this.dialogRef.close({event: 'close', data: of(this.currentImage)});
  }

  private updateQueryParams(imageId: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {image: imageId},
        queryParamsHandling: 'merge'
      });
  }

  disapprove(id) {
    this.imageDetailsService
      .disapprove(id)
      .subscribe(this.onSuccess, this.onError);
  }

  approve(id) {
    this.imageDetailsService
      .approve(id)
      .subscribe(this.onSuccess, this.onError);
  }

  private onSuccess(data) {
    this.currentImage = data;
    // this.close.emit();
  }

  // display warning
  private onError(response) {
    if (response.status == 401) {
      console.log("You are not authorized for this action.");
    } else if (response.status == 404) {
      console.log("Service unavailable");
    } else {
      console.log("Something went wrong. Trt later.");
    }
  }

  private setMetaTagsForSocialMedia(currentImage: ImageDetailsResponse){
    this.titleTagService.setTitle(`Iris Green Witness - ${currentImage.userName}'s portfolio`);
    this.titleTagService.setSocialMediaTags(
      `https://www.iris-green-witness.org/portfolio?photographer=${currentImage.userId}&image=${currentImage.id}`,
      `Iris Green Witness - ${currentImage.userName}'s portfolio`,
      `Iris Green Witness - ${currentImage.userName}'s portfolio`,
      currentImage.imageUrl);
  }
}
