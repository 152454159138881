import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

/**
 * This is NOT a Global Service
 * It Is a prototype (one instance per injection)
 */
@Injectable()
export class ResetPasswordService {

  constructor(private http: HttpClient) {
  }

  /*
      Request to reset account's password
      Application server responds with Status OK
   */
  restPassword(password, token) {
    // POST /api/auth/password_reset
    return this.http.post(
      "/api/auth/password_reset",
      {password, token},
      {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        observe: 'response'
      });
  }

  /*
    Request to validate the given token
    Application server responds with Status OK if token is valid
    otherwise with Status NOT_FOUND
 */
  validateToken(token) {
    // POST /api/auth/password_reset/valid
    return this.http.post(
      "/api/auth/password_reset/valid", token, {observe: 'response'});
  }
}
