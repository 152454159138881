export class DateUtilities {
  static epochTimeToDate(epochTime: number) {
    const date = new Date(0);
    date.setUTCSeconds(epochTime);
    return date;
  }

  static isGreaterThanToday(dateToCompare: Date) {
    const now = new Date();
    return dateToCompare > now;
  }
}
