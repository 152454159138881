export class GalleryUtilities {

  static gridRow(imageIndex: number) {
    const canonicalIndex = imageIndex + 1;
    return canonicalIndex % 3 ? Math.round(imageIndex / 3) + 1 : Math.round(imageIndex / 3);
  }

  static gridColumn(imageIndex: number) {
    const canonicalIndex = imageIndex + 1;
    return canonicalIndex % 3 ? canonicalIndex % 3 : 3;
  }
}
