import { Component } from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'iris-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  constructor(private breakpointObserver: BreakpointObserver){}
  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 600px)');
  }
}
