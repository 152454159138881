import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
/**
 * This is NOT a Global Service
 * It Is a prototype (one instance per injection)
 */
@Injectable()
export class SignupService {
  constructor(private http: HttpClient) {}

  /*
  Request to register a new appUser
  Application server response with appUser's info {id, fullName, email}
   */
  signup(email, fullName, nationality, password) {
    // POST /api/auth/registration
    return this.http.post(
      "/api/auth/registration",
      { email, fullName, nationality, password },
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        observe: "response"
      }
    );
  }
}
