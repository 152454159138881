import { Pipe, PipeTransform } from '@angular/core';
import {ImageDetailsResponse} from "../../features/image-upload/models/image-details.response";
import {PhotographerResponse} from "../../features/image-upload/models/photographer.response";
@Pipe({
  name: 'filterPhotographersByLocation'
})
export class FilterPhotographersByLocationsPipe implements PipeTransform {
  transform(photographers: PhotographerResponse[], locationFilter: string): any {
    if (!locationFilter) { return photographers; }
    return photographers.filter(photographer => photographer.visitedLocations.includes(locationFilter));
  }
}
