import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { SignupService } from "../../services/signup.service";
import {
  ConfirmPasswordService,
  ConfirmPasswordStateMatcher
} from "../../shared/confirm-password.service";
import { CountryService } from "../../../../shared/services/country/country.service";

@Component({
  selector: "bh-signup",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit, OnDestroy {
  signupSubscription: Subscription;
  showSpinner = false;
  formGroup: FormGroup;
  matcher = new ConfirmPasswordStateMatcher();
  countrySubscription: Subscription;
  nationalities;

  constructor(
    private router: Router,
    private signupService: SignupService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private confirmPasswordService: ConfirmPasswordService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      email: ["", [Validators.email, Validators.required]],
      fullName: ["", [Validators.required]],
      nationality: ["", [Validators.required]],
      passGroup: this.fb.group(
        {
          password: ["", [Validators.required]],
          confirmPassword: [""]
        },
        { validator: this.confirmPasswordService.checkPasswords }
      )
    });

    this.countrySubscription = this.countryService
      .getNationalities()
      .subscribe(nationalities => (this.nationalities = nationalities));
  }

  /*
  User clicks on sign up
  A new request is sent to API in order to register the new appUser
  On Success the appUser redirected to login page
  To login the appUser  has to confirm his registration by email
   */
  signup(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.showSpinner = true;
    // go to login page
    const onSuccess = () => {
      this.toastr.success(
        "You are now register. Please check your email for activation."
      );
      this.showSpinner = false;
    };
    //display error message
    const onError = response => {
      if (response.error && response.error.message) {
        this.toastr.warning(response.error.message);
      } else if (response.status !== 200) {
        this.toastr.warning("Service unavailable");
      }
      this.showSpinner = false;
    };
    // request to register a new appUser
    const passGroup = form["controls"].passGroup["controls"];
    this.signupSubscription = this.signupService
      .signup(
        form.controls.email.value,
        form.controls.fullName.value,
        form.controls.nationality.value,
        passGroup.password.value
      )
      .subscribe(onSuccess, onError);
  }

  ngOnDestroy(): void {
    if (this.signupSubscription) {
      this.signupSubscription.unsubscribe();
    }

    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
  }
}
