import {Component, OnInit} from '@angular/core';
import {ImageDetailsService} from "../../shared/services/imageDetails/image-details.service";
import {ImageDetailsResponse} from "../image-upload/models/image-details.response";
import {Router} from "@angular/router";

@Component({
  selector: 'iris-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  filteredImages: ImageDetailsResponse[];

  constructor(private imageDetailsService: ImageDetailsService, private router: Router) {
  }

  ngOnInit() {

  }
  onChangeSearch(value: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.imageDetailsService.search(value).subscribe(data => this.filteredImages = data)
  }


  onImageClick(userId: number) {
    this.router.navigate(['/portfolio'], {queryParams: {photographer: userId}});
  }


}
