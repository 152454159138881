import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserDetails} from '../../../features/profile/models/user-details'
import {Observable} from "rxjs";


/**
 * This is NOT a Global Service
 * It Is a prototype (one instance per injection)
 */
@Injectable()
export class UserDetailsService {

  constructor(private http: HttpClient) {
  }


  getDetails(userId: number): Observable<UserDetails> {
    // GET /api/users/{id}/details
    return this.http.get("/api/users/" + userId + "/details",) as Observable<UserDetails>;
  }

  updateDetails(id: number, userDetails: UserDetails) {
    // POST /api/users/{id}/details
    return this.http.post(
      "/api/users/" + id + "/details",
      userDetails,
      {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        observe: 'response'
      });
  }
}
