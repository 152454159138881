import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserModel } from "../../models/user.model";

@Injectable({
  providedIn: "root"
})
export class AuthorizationResource {
  private readonly endpoint = "/api/auth";

  constructor(private http: HttpClient) {}

  // TODO not used. see login service
  login(email: string, password: string) {
    return this.http.post(`${this.endpoint}`, {
      email,
      password
    });
  }

  registerUser(email: string, password: string, name: string) {
    return this.http.post(`${this.endpoint}/register`, {
      email,
      password,
      name
    });
  }

  verifyUser(verificationToken: string) {
    return this.http.post(`${this.endpoint}/verify/${verificationToken}`, {});
  }

  retrieveUserDetails(id: string): Observable<UserModel> {
    return this.http.get<UserModel>(`api/users/${id}`);
  }
}
