import {Pipe, PipeTransform} from '@angular/core';
import {DateFilterModel} from "../models/date-filter.model";
import {ImageDetailsResponse} from "../../features/image-upload/models/image-details.response";

@Pipe({
  name: 'filterByDate'
})
export class FilterByDatePipe implements PipeTransform {

  transform(images: ImageDetailsResponse[], dateFilter: DateFilterModel): any {
    if (!dateFilter) {
      return images;
    }
    return images.filter(image => this.isDateInRage(new Date(image.capturedAt), dateFilter));
  }

  private isDateInRage(date: Date, range: DateFilterModel): boolean {

    if(!date || !(range && range.startDate && range.endDate)){
      return true
    }
    return date.getTime() >= range.startDate.getTime() && date.getTime() <= range.endDate.getTime();
  }
}
