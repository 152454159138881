import { Component } from '@angular/core';

@Component({
  selector: 'iris-supporters-gr',
  templateUrl: './supporters-gr.component.html',
  styleUrls: ['./supporters-gr.component.scss']
})
export class SupportersGrComponent{


}
