import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ImageDetailsResponse } from "../../../image-upload/models/image-details.response";
import { ImageDetailsService } from "../../../../shared/services/imageDetails/image-details.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "iris-admin-image-info-modal",
  templateUrl: "./admin-image-info-modal.component.html",
  styleUrls: ["./admin-image-info-modal.component.css"]
})
export class AdminImageInfoModalComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  ngOnInit(): void {
  }

  constructor(
    public dialogRef: MatDialogRef<AdminImageInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public imageDetails: ImageDetailsResponse,
    private imageDetailsService: ImageDetailsService,
    private toastr: ToastrService
  ) {}

  disapprove(id) {
    this.imageDetailsService
      .disapprove(id)
      .subscribe(this.onSuccess, this.onError);
  }

  approve(id) {
    this.imageDetailsService
      .approve(id)
      .subscribe(this.onSuccess, this.onError);
  }

  private onSuccess(data) {
    // this.close.emit();
   // this.toastr.info("Image status updated.");
  }
  // display warning
  private onError(response) {
    if (response.status == 401) {
      this.toastr.error("You are not authorized for this action.");
    } else if (response.status == 404) {
      this.toastr.error("Service unavailable");
    } else {
      this.toastr.error("Something went wrong. Trt later.");
    }
  }
}
