import { Injectable } from "@angular/core";
import { CountryResource } from "./country.resource";
import {Observable} from 'rxjs'

@Injectable({
  providedIn: "root"
})
export class CountryService {
  constructor(private countryResource: CountryResource) {}

  getNationalities():Observable<any> {
    return this.countryResource.getNationalities();
  }
}
