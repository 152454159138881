import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AuthorizationService } from "../../../shared/services/authorization/authorization.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
  selector: "bh-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"]
})
export class NavBarComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<void>();
  isLoggedIn$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authorizationService.isLoggedIn().subscribe();
    this.isLoggedIn$ = this.authorizationService.isLoggedIn$;
    this.isAdmin$ = this.authorizationService.isAdmin$;
  }

  logout() {
    this.authorizationService.logout();
    this.router.navigate(["home"]);
  }
}
