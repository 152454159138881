import { Pipe, PipeTransform } from '@angular/core';
import {ImageDetailsResponse} from "../../features/image-upload/models/image-details.response";
@Pipe({
  name: 'filterByPhotographer'
})
export class FilterByPhotographerPipe implements PipeTransform {
  transform(imageDetails: ImageDetailsResponse[], photographerFilter: string): any {
    if (!photographerFilter) { return imageDetails; }
    const filtered =  imageDetails.filter(image => image.userId == +photographerFilter);
    return filtered;
  }
}
