import {NgModule} from "@angular/core";
import {GalleryModule} from "@ks89/angular-modal-gallery";
import {SharedModule} from "../../shared/shared.module";
import {AdminGridGalleryComponent} from "./pages/grid-gallery/admin-grid-gallery.component";
import {ImageDetailsService} from "../../shared/services/imageDetails/image-details.service";
import {AdminImageInfoModalComponent} from "./components/image-info-modal/admin-image-info-modal.component";
import {MatDialogModule} from "@angular/material";
import {AgmCoreModule} from '@agm/core';
import {AdminGalleryComponent} from './pages/gallery/admin-gallery.component'
import {LightboxModalComponent} from "../../shared/components/lightbox/lightbox-modal.component";
import {LightboxPageComponent} from "./components/lightbox-page/lightbox-page.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    AdminGridGalleryComponent,
    AdminImageInfoModalComponent,
    LightboxPageComponent,
    AdminGalleryComponent
  ],
    imports: [
        SharedModule,
        GalleryModule,
        MatDialogModule,
        AgmCoreModule,
        TranslateModule
    ],
  providers: [
    ImageDetailsService
  ],
  exports: [
    AdminGalleryComponent
  ],
  entryComponents: [
    AdminImageInfoModalComponent,
    LightboxModalComponent
  ]
})
export class AdminImageGalleryModule {
}
