import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs/index';
import {ResetPasswordService} from '../../services/reset-password.service';
import {
  ConfirmPasswordService,
  ConfirmPasswordStateMatcher
} from '../../shared/confirm-password.service';


@Component({
  selector: 'bh-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  token: string;
  forgotPassSubscription: Subscription;
  tokenSubscription: Subscription;
  showSpinner = false;
  formGroup: FormGroup;
  matcher = new ConfirmPasswordStateMatcher();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private resetPasswordService: ResetPasswordService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              private confirmPasswordService: ConfirmPasswordService) {
  }

  ngOnInit() {
    // get token  from url parameters
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      // In case of an invalid token go to home page
      const callBack =(response) => {
        if (response.status !== 200) {
          this.router.navigate(["home"]);
        }
      };
      // In case there is no token in parameters
      if (this.token == null) {
        this.router.navigate(["home"]);
      } else {
        // request to validate the the token
        this.tokenSubscription = this.resetPasswordService.validateToken(this.token)
        .subscribe(callBack,callBack);
      }
    });

    this.formGroup = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['']
    }, {validator: this.confirmPasswordService.checkPasswords});
  }


  resetPassword(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.showSpinner = true;
    // go to log in
    const onSuccess = () => {
      this.router.navigate(["/login-register"]);
      this.showSpinner = false;
    };
    // display warning
    const onError = (response) => {
      if(response.error && response.error.message){
        this.toastr.warning(response.error.message);
      } else if (response.status != 200) {
        this.toastr.warning("Service unavailable");
      }
      this.showSpinner = false;
    };
    // request to reset the password
    this.forgotPassSubscription = this.resetPasswordService.restPassword(form.controls.password.value, this.token)
    .subscribe(onSuccess, onError);
  }

  ngOnDestroy(): void {
    if (this.forgotPassSubscription) {
      this.forgotPassSubscription.unsubscribe();
    }
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
  }
}
