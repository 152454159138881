import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {PhotographersComponent} from "./page/photographers/photographers.component";
import {ImageInfoModalComponent} from "./components/image-info-modal/image-info-modal.component";
import {MatDialogModule} from "@angular/material";
import {AgmCoreModule} from '@agm/core'
import {LayoutModule} from "@angular/cdk/layout";
import { PortfolioComponent } from './page/portfolio/portfolio.component';
import {PhotographerService} from "../../shared/services/photographer/photographer.service";
import {CommentsService} from "../../shared/services/imageDetails/comments.service";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [PhotographersComponent, ImageInfoModalComponent, PortfolioComponent],
    imports: [SharedModule, MatDialogModule, AgmCoreModule, LayoutModule, TranslateModule],
  providers: [PhotographerService, CommentsService],
  entryComponents: [ImageInfoModalComponent]
})
export class PhotographersModule {
}
