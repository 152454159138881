import { NgModule } from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {SearchComponent} from "./search.component";
import {ImageDetailsService} from "../../shared/services/imageDetails/image-details.service";
import {AutocompleteLibModule} from "angular-ng-autocomplete";

@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    SharedModule,
    TranslateModule,
    AutocompleteLibModule
  ],
  exports:[
    SearchComponent
  ],
  providers:[
    ImageDetailsService
  ]
})
export class SearchModule { }
