import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
/**
 * This is NOT a Global Service
 * It Is a prototype (one instance per injection)
 */
@Injectable()
export class ForgotPasswordService {

  constructor(private http: HttpClient) {}

  /*
  Request to reset account's password
  Application server response with Status OK
   */
  sendMail(email) {
    // POST /api/auth/password_reset/mail
    return this.http.post(
      "/api/auth/password_reset/mail",
      {email},
      {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        observe: 'response'
      });
  }

}
