import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {LoginComponent} from './pages/login/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import {ForgotPasswordService} from './services/forgot-password.service';
import {LoginService} from './services/login.service';
import {ResetPasswordService} from './services/reset-password.service';
import {SignupService} from './services/signup.service';
import {ConfirmPasswordService} from './shared/confirm-password.service';
import {RegisterComponent} from './pages/register/register.component'
import {HttpXsrfInterceptor} from "./services/httpXsrf.interceptor";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ResetPasswordComponent
  ],
    imports: [
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'iris',
            headerName: 'My-Xsrf-Header',
        }),
        SharedModule,
        TranslateModule
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    LoginService,
    SignupService,
    ForgotPasswordService,
    ResetPasswordService,
    ConfirmPasswordService
  ],
  exports: [
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ResetPasswordComponent
  ]
})
export class AuthModule { }
