import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {UserState} from "../../../../shared/services/user/user.state";
import {LoginService} from "../../services/login.service";
import {AuthorizationService} from "../../../../shared/services/authorization/authorization.service";
import {ConfirmPasswordService, ConfirmPasswordStateMatcher} from "../../shared/confirm-password.service";
import {SignupService} from "../../services/signup.service";
import {CountryService} from "../../../../shared/services/country/country.service";

@Component({
  selector: "bh-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  loginSubscription: Subscription;
  signupSubscription: Subscription;
  hide = true;
  showSpinner = false;
  loginFormGroup: FormGroup;
  signupFormGroup: FormGroup;
  isTabSelected = [true, false];
  matcher = new ConfirmPasswordStateMatcher();
  countrySubscription: Subscription;
  nationalities;

  constructor(
    private router: Router,
    private signupService: SignupService,
    private loginService: LoginService,
    private userState: UserState,
    private authService: AuthorizationService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private confirmPasswordService: ConfirmPasswordService,
    private countryService: CountryService
  ) {
  }

  ngOnInit() {
    /*    this.authService.isLoggedIn$.subscribe(isLoggedIn => {
          if(isLoggedIn){
            this.router.navigate(["profile"]);
          }
        });*/

    this.loginFormGroup = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]]
    });

    this.signupFormGroup = this.fb.group({
      email: ["", [Validators.email, Validators.required]],
      fullName: ["", [Validators.required]],
      nationality: ["", [Validators.required]],
      passGroup: this.fb.group(
        {
          password: ["", [Validators.required]],
          confirmPassword: [""]
        },
        {validator: this.confirmPasswordService.checkPasswords}
      )
    });

    this.countrySubscription = this.countryService
      .getNationalities()
      .subscribe(nationalities => (this.nationalities = nationalities));
  }

  signup(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.showSpinner = true;
    // go to login page
    const onSuccess = () => {
      this.toastr.success(
        "You are now register. Please check your email for activation."
      );
      this.showSpinner = false;
    };
    //display error message
    const onError = response => {
      if (response.error && response.error.message) {
        this.toastr.warning(response.error.message);
      } else if (response.status !== 200) {
        this.toastr.warning("Service unavailable");
      }
      this.showSpinner = false;
    };
    // request to register a new appUser
    const passGroup = form["controls"].passGroup["controls"];
    this.signupSubscription = this.signupService
      .signup(
        form.controls.email.value,
        form.controls.fullName.value,
        form.controls.nationality.value,
        passGroup.password.value
      )
      .subscribe(onSuccess, onError);
  }

  login(form: FormGroup) {
    this.showSpinner = true;
    // get JWT token from header and initialize the appUser
    const onSuccess = data => {
      const token = data.headers.get("Authorization");
      this.authService.logout();
      this.authService.handleAuthenticationResponse(token);
      this.authService.isLoggedIn().subscribe();
      this.showSpinner = false;
      this.router.navigate(["home"]);
    };
    // display warning
    const onError = response => {
      if (response.status == 401) {
        this.toastr.error("Wrong email or password");
      } else if (response.status == 404) {
        this.toastr.error("Service unavailable");
      }
      this.showSpinner = false;
    };
    // request to login
    this.loginSubscription = this.loginService
      .login(form.controls.email.value, form.controls.password.value)
      .subscribe(onSuccess, onError);
  }

  selectTab(tabId: number) {
    this.isTabSelected = [false, false];
    this.isTabSelected[tabId] = true;
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }

    if (this.signupSubscription) {
      this.signupSubscription.unsubscribe();
    }

    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
  }

}
