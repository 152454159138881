import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {HomeComponent} from './home.component';
import {LightboxModalComponent} from "../../shared/components/lightbox/lightbox-modal.component";
import {AgmCoreModule} from "@agm/core";
import {TranslateModule} from "@ngx-translate/core";
import { SupportersGrComponent } from './supporters-gr/supporters-gr.component';
import {SupportersEnComponent} from "./supporters-en/supporters-en.component";

@NgModule({
  declarations: [
    HomeComponent,
    SupportersGrComponent,
    SupportersEnComponent
  ],
    imports: [
        SharedModule,
        AgmCoreModule,
        TranslateModule
    ],
  exports: [
    HomeComponent
  ],
  entryComponents: [
    LightboxModalComponent
  ]
})
export class HomeModule {
}
