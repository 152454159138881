import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {ProfileComponent} from './pages/profile/profile.component';
import {UserDetailsService} from '../../shared/services/user/user-details.service';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ProfileComponent
  ],
    imports: [
        SharedModule,
        TranslateModule
    ],
  exports: [
    ProfileComponent
  ],
  providers: [
    UserDetailsService
  ]
})
export class ProfileModule { }
