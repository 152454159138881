import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PhotographerResponse} from "../../../features/image-upload/models/photographer.response";

@Injectable()
export class PhotographerService {
  private readonly endpoint = "/api/photographers";

  constructor(private http: HttpClient) {
  }

  findAll(): Observable<PhotographerResponse[]> {
    // GET /api/images
    return this.http.get(`${this.endpoint}`) as Observable<PhotographerResponse[]>;
  }

}
