import {Component, OnInit} from '@angular/core';
import {map} from "rxjs/operators";
import {ImageDetailsResponse} from "../image-upload/models/image-details.response";
import {ImageDetailsService} from "../../shared/services/imageDetails/image-details.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'iris-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  serverImages: ImageDetailsResponse[] = [];

  constructor(
    private imageUploadService: ImageDetailsService,
    public modal: MatDialog
  ) {
  }

  ngOnInit() {
    this.imageUploadService
      .findAll()
      .pipe(
        map(serverImages => this.serverImages = serverImages.sort((a,b) => a.likes > b.likes? -1: 1))
      )
      .subscribe();
  }
}
