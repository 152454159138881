import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { UserModel } from "../../models/user.model";
import { CookieService } from "ngx-cookie-service";
import { TokenUtilities } from "../../utilities/token.utilities";
import { catchError, tap } from "rxjs/operators";
import { AuthorizationResource } from "../authorization/authorization.resource";

@Injectable({
  providedIn: "root"
})
export class UserState {
  private readonly USER_COOKIE_NAME = "iris";

  private _user$ = new BehaviorSubject<UserModel>(null);

  constructor(
    private cookieService: CookieService,
    private authorizationResource: AuthorizationResource
  ) {}

  getUser(): UserModel {
    return this._user$.value;
  }
  setUser(): Observable<UserModel> {
    const userTokenCookie = this.cookieService.get(this.USER_COOKIE_NAME);

    if (userTokenCookie && !this._user$.value) {
      const { id: userId } = JSON.parse(
        TokenUtilities.getDecodedToken(userTokenCookie).sub
      );
      return this.authorizationResource.retrieveUserDetails(userId).pipe(
        tap(userDetails => {
          if (!userDetails) {
            // if userDetails are null then it means that the user either does not exist or the token is expired
            return throwError("USER DOES NOT EXIST");
          }
          this._user$.next({ ...userDetails, id: userId });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
    }

    return this._user$;
  }

  clearUser() {
    this._user$.next(null);
  }

  setUserCookie(token: any, expires?) {
    this.cookieService.set(this.USER_COOKIE_NAME, token, expires, "/");
  }

  getUserCookie() {
    return this.cookieService.get(this.USER_COOKIE_NAME);
  }
}
