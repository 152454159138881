import { Component, OnInit} from "@angular/core";
import {Location} from "@angular-material-extensions/google-maps-autocomplete";
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from "moment";
import {PreviewImageModel} from "../../models/preview-image.model";
import {ImageDetailsService} from "../../../../shared/services/imageDetails/image-details.service";
import {ImageDetailsRequest} from "../../models/image-details.request";
import {ToastrService} from "ngx-toastr";
import {UserState} from "../../../../shared/services/user/user.state";
import {CRETE_LOCATION} from "../../../../shared/models/crete-location";
import {POINT_OF_INTEREST} from "../../../../shared/models/point-of-interest";
import {MapsAPILoader} from "@agm/core";
import {BreakpointObserver} from "@angular/cdk/layout";

const moment = _rollupMoment || _moment;
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "L"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: "iris-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: "en-GB"},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
  // encapsulation: ViewEncapsulation.None
})
export class ImageUploadComponent implements OnInit {
  locations = POINT_OF_INTEREST;
  isImageUploading = false;
  previewImage: PreviewImageModel;
  form: FormGroup;
  autoCompleteAddress: String;
  isMapHidden = true;
  private geoCoder;

  public zoom: number;
  public latitude: number;
  public longitude: number;
  now = new Date();
  mapSearch: any;

  constructor(
    private fb: FormBuilder,
    private imageUploadService: ImageDetailsService,
    private toastr: ToastrService,
    private userState: UserState,
    private mapsAPILoader: MapsAPILoader,
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit() {
    // build personal info form
    this.form = this.fb.group({
      experience:[""],
      encountered:[""],
      problems:[""],
      suggestions:[""],
      location: ["", [Validators.required]],
      capturedAt: ["", [Validators.required]],
      gdpr: [false, [Validators.requiredTrue]],
      comments: [""],
      image: ["", [Validators.required]],
      address: this.fb.group({
        latitude: [CRETE_LOCATION.lat],
        longitude: [CRETE_LOCATION.lng]
      })
    });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.setCurrentPosition();
    });


  }


  uploadFormData(form: FormGroup, formDirective: FormGroupDirective) {
    // show spinner
    this.isImageUploading = true;

    // prepare data
    const captureDate = new Date(form.value.capturedAt);
    const data: ImageDetailsRequest = {
      location: form.value.location,
      capturedAt: captureDate.toLocaleDateString('en-GB'),
      gdpr: form.value.gdpr,
      comments: form.value.comments,
      image: form.value.image[0],
      longitude: form.value.address.longitude,
      latitude: form.value.address.latitude,
      userId: this.userState.getUser().id,
      experience: form.value.experience,
      encountered: form.value.encountered,
      problems: form.value.problems,
      suggestions: form.value.suggestions,
    };

    // display success message
    const onSuccess = () => {
      this.toastr.success("Your photograph uploaded successfully.");
      // reset form and validators
      this.resetForm(formDirective);
      this.isImageUploading = false;
    };
    //display error message
    const onError = response => {
      if (response.error && response.error.message) {
        this.toastr.warning(response.error.message);
      } else if (response.status !== 200) {
        this.toastr.warning("Service unavailable");
      }
      this.isImageUploading = false;
    };

    this.imageUploadService
      .upload(data)
      .subscribe(onSuccess, onError);
  }

  onImageSelect(selectedImages: FileList) {
    if (!selectedImages.length) {
      return;
    }
    this.loadPreviewImage(selectedImages.item(0));
  }

  onLocationSelected(location: Location) {
    this.longitude = location.longitude;
    this.latitude = location.latitude;
    this.form.patchValue({address: {longitude: location.longitude}});
    this.form.patchValue({address: {latitude: location.latitude}});
  }

  onMarkerDragEnd($event: any) {
    const newCoordinates = [$event.coords.lat, $event.coords.lng];
    this.form.patchValue({address: {longitude: newCoordinates[1]}});
    this.form.patchValue({address: {latitude: newCoordinates[0]}});
    this.setAutoCompleteAddress(newCoordinates[1], newCoordinates[0]);
  }

  private loadPreviewImage(imageFile: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onload = () => {
      this.previewImage = {
        id: null,
        thumbnailUrl: reader.result,
        name: imageFile.name
      };
    };
  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.setAutoCompleteAddress(this.longitude, this.latitude);
        this.form.patchValue({address: {longitude: this.longitude}});
        this.form.patchValue({address: {latitude: this.latitude}});
        this.zoom = 12;
      });
    }
  }

  private resetForm(formDirective: FormGroupDirective) {
    formDirective.resetForm();
    this.form.reset();

    /*    this.form.patchValue({
          location: "",
          capturedAt: "",
          gdpr: false,
          comments: "",
          address: {longitude: this.CRETA_LOCATION.lng, latitude: this.CRETA_LOCATION.lat}
        });*/
    this.resetMap();
    this.previewImage = undefined;
  }

  private resetMap() {
    this.longitude = CRETE_LOCATION.lng;
    this.latitude = CRETE_LOCATION.lat;
    this.setAutoCompleteAddress(this.longitude, this.latitude);
    this.zoom = 8;
  }

  setAutoCompleteAddress(longitude, latitude) {
    this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.autoCompleteAddress = results[0].formatted_address;
        } else {
          this.autoCompleteAddress = "";
        }
      } else {
        this.autoCompleteAddress = "";
      }

    });
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
}
