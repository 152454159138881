import {NgModule} from "@angular/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";

import {AppComponent} from "./app.component";
import {CoreModule} from "./core/core.module";
import {ProfileModule} from "./features/profile/profile.module";
import {AuthModule} from "./features/auth/auth.module";
import {HomeModule} from "./features/home/home.module";
import {ToastrModule} from "ngx-toastr";
import {CookieService} from "ngx-cookie-service";
import {ImageUploadModule} from "./features/image-upload/image-upload.module";
import {MatGoogleMapsAutocompleteModule} from "@angular-material-extensions/google-maps-autocomplete";
import {AgmCoreModule} from "@agm/core";
import "hammerjs";
import "mousetrap";
import {GalleryModule} from "@ks89/angular-modal-gallery";
import {PhotographersModule} from "./features/photographers/photographers.module";
import {AdminImageGalleryModule} from './features/admin-image-gallery/admin-image-gallery.module'
import {ContactModule} from './features/contact/contact.module';
import {LandingComponent} from './features/landing/landing.component';
import {AboutModule} from "./features/about/about.module";
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core'
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {SharedModule} from "./shared/shared.module";
import {SearchModule} from "./features/search/search.module";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
  declarations: [AppComponent, LandingComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SearchModule,
    AboutModule,
    BrowserAnimationsModule,
    ContactModule,
    CoreModule,
    AuthModule,
    ProfileModule,
    ImageUploadModule,
    PhotographersModule,
    AdminImageGalleryModule,
    HomeModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true
    }),
    MatGoogleMapsAutocompleteModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDLPe6PjZiCIbnNLtp11QMl8Ku5MxPSSt8",
      libraries: ["places"]
    }),
    GalleryModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}
