import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { TokenUtilities } from "../../utilities/token.utilities";
import { UserState } from "../user/user.state";

@Injectable({
  providedIn: "root"
})
export class AuthorizationService {
  // tslint:disable:variable-name
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  // tslint:enable:variable-name
  private _isAdmin$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cookieService: CookieService,
    private userService: UserState
  ) {}

  setIsLoggedIn(value: boolean) {
    this._isLoggedIn$.next(value);
  }

  setIsAdmin(value: boolean) {
    this._isAdmin$.next(value);
  }

  get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  get isAdmin$(): Observable<boolean> {
    return this._isAdmin$.asObservable();
  }

  // will be attached to guards
  isLoggedIn(): Observable<boolean> {
    if (TokenUtilities.isTokenExpired(this.userService.getUserCookie())) {
      this.logout();
      return of(null);
    }

    return this.userService.setUser().pipe(
      tap(userDetails => {
        this.setIsLoggedIn(!!userDetails);
        this.setIsAdmin(userDetails.role === "ADMIN");
      }),
      switchMap(() => this._isLoggedIn$),
      catchError(err => {
        this.logout();
        return throwError(err);
      })
    );
  }

  logout() {
    this.cookieService.deleteAll("/");
    localStorage.clear();
    this._isLoggedIn$.next(false);
    this._isAdmin$.next(false);

    this.userService.clearUser();
  }

  handleAuthenticationResponse(token: string) {
    this.userService.setUserCookie(
      token,
      TokenUtilities.getExpirationDateFromToken(token)
    );
  }
}
