import {Component, OnInit} from '@angular/core';
import {ImageDetailsService} from "../../shared/services/imageDetails/image-details.service";
import {ImageDetailsResponse} from "../image-upload/models/image-details.response";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {BreakpointObserver} from "@angular/cdk/layout";
import {CRETE_LOCATION} from "../../shared/models/crete-location";
import {Router} from "@angular/router";
import {MAP_STYLE} from "../../shared/models/map-styles";
import {map, tap} from "rxjs/operators";
// @ts-ignore
import { ControlPosition, FullscreenControlOptions, MapTypeControlOptions} from "@agm/core";
import {TranslateService} from "@ngx-translate/core";
import {TitleTagService} from "../../shared/services/tag/title-tag.service";

@Component({
  selector: 'bh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  imagesDetails$: Observable<ImageDetailsResponse[]>;
  orderedImagesDetails$: Observable<ImageDetailsResponse[]>;
  carouselVisibility = 'hidden';
  CRETE_LOCATION = CRETE_LOCATION;
  mapStyle = MAP_STYLE;
  mapTypeControlOptions: MapTypeControlOptions;
  fullScreenOptions: FullscreenControlOptions;

  constructor(
    private imageDetailsService: ImageDetailsService,
    public modal: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public translate: TranslateService,
    private titleTagService: TitleTagService
  ) {
  }

  ngOnInit() {
    this.mapTypeControlOptions = {position: ControlPosition.LEFT_BOTTOM};
    this.fullScreenOptions = {position: ControlPosition.TOP_LEFT};
    this.imagesDetails$ = this.imageDetailsService.findAll();
    this.orderedImagesDetails$ = this.imagesDetails$.pipe(
      map(serverImages => serverImages.sort((a, b) => a.likes > b.likes ? -1 : 1)),
      tap(shortedImages => this.setMetaTagsForSocialMedia(shortedImages[0]))
    );
  }

  revealSlider() {
    this.carouselVisibility = 'visible';
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  onImageClick(userId: number) {
    this.router.navigate(['/portfolio'], {queryParams: {photographer: userId}});
  }

  private setMetaTagsForSocialMedia(currentImage: ImageDetailsResponse){
    this.titleTagService.setTitle(`Iris Green Witness - home page`);
    this.titleTagService.setSocialMediaTags(
      `https://www.iris-green-witness.org/home`,
      `Iris Green Witness - home page`,
      `Iris Green Witness - home page`,
      currentImage.imageUrl);
  }
}
