import { NgModule } from '@angular/core';
import {AppRoutingModule} from '../app-routing.module';
import {SharedModule} from '../shared/shared.module';
import { NavBarComponent } from './header/nav-bar/nav-bar.component';
import {FooterComponent} from "./footer/footer.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent
  ],
    imports: [
        SharedModule,
        AppRoutingModule,
        TranslateModule
    ],
  exports: [
    NavBarComponent,
    FooterComponent
  ]
})
export class CoreModule { }
