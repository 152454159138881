import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ImageDetailsRequest} from "../../../features/image-upload/models/image-details.request";
import {FormUtilities} from "../../utilities/form.utilities";
import {ImageDetailsResponse} from "../../../features/image-upload/models/image-details.response";
import {Observable} from "rxjs";
import {UserState} from "../user/user.state";

@Injectable()
export class ImageDetailsService {
  private readonly endpoint = "/api/images";

  constructor(
    private http: HttpClient,
    private userState: UserState) {
  }

  upload(data: ImageDetailsRequest) {
    // POST /api/images/{id}
    return this.http.post(`${this.endpoint}`, FormUtilities.toFormData(data), {
      observe: "response"
    });
  }

  findOne(id: string): Observable<ImageDetailsResponse> {
    // GET /api/images
    return this.http.get(`${this.endpoint}/${id}`) as Observable<ImageDetailsResponse>;
  }

  findAll(): Observable<ImageDetailsResponse[]> {
    // GET /api/images
    return this.http.get(`${this.endpoint}`) as Observable<ImageDetailsResponse[]>;
  }

  findAllRestricted(): Observable<ImageDetailsResponse[]> {
    // GET /api/images/restricted
    return this.http.get(`${this.endpoint}/restricted`) as Observable<ImageDetailsResponse[]>;
  }

  approve(id: string) {
    // POST /api/images/restricted/{id}/approve
    return this.http.post(`${this.endpoint}/restricted/${id}/approve`, {
      observe: "response"
    });
  }

  like(id: string): Observable<ImageDetailsResponse> {
    // POST /api/images/{id}/like?userId=
    const user = this.userState.getUser();
    if(!user){
      return
    }
    return this.http.post(`${this.endpoint}/${id}/like?userId=${user.id}`, {
      observe: "response"
    }) as Observable<ImageDetailsResponse>;
  }

  disapprove(id: string) {
    // POST /api/images/restricted/{id}/disapprove
    return this.http.post(`${this.endpoint}/restricted/${id}/disapprove`, {
      observe: "response"
    });
  }

  //TODO deprecated
  findApprovedImage(imageId: string): Observable<ImageDetailsResponse> {
    // GET /api/images/approved/{id}
    return this.http.get(`${this.endpoint}/approved/${imageId}`) as Observable<ImageDetailsResponse>;
  }

  findAllByUser(userId: string): Observable<ImageDetailsResponse[]> {
    // GET /api/images/approved/{id}
    return this.http.get(`${this.endpoint}?userId=${userId}`) as Observable<ImageDetailsResponse[]>;
  }

  search(term: string): Observable<ImageDetailsResponse[]> {
    // GET /api/images/search?term=
    return this.http.get(`${this.endpoint}/search?term=${term}`) as Observable<ImageDetailsResponse[]>;
  }
}
