import { Component } from '@angular/core';

@Component({
  selector: 'iris-supporters-en',
  templateUrl: './supporters-en.component.html',
  styleUrls: ['./supporters-en.component.scss']
})
export class SupportersEnComponent {


}
