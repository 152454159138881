import * as base64 from "js-base64";
import { DateUtilities } from "./date.utilities";

export class TokenUtilities {
  static getDecodedToken(token: string) {
    if (!token) {
      return;
    }
    const tokenPayload = token.split(".")[1];
    return JSON.parse(base64.Base64.decode(tokenPayload));
  }

  static getExpirationDateFromToken(token: string): Date {

    const decodedTokenPayload = TokenUtilities.getDecodedToken(token);
    if (!decodedTokenPayload) {
      return;
    }
    return DateUtilities.epochTimeToDate(decodedTokenPayload.exp) as Date;
  }

  static isTokenExpired(token: string) {
    if (token && token.indexOf("null") == -1) {
      return !DateUtilities.isGreaterThanToday(
        TokenUtilities.getExpirationDateFromToken(token)
      );
    } else {
      return true;
    }
  }
}
