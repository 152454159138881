import { NgModule } from '@angular/core';
import {AboutComponent} from "./about.component";
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [AboutComponent],
    imports: [
        SharedModule,
        TranslateModule
    ],
  exports:[
    AboutComponent
  ]
})
export class AboutModule { }
