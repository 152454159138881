import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

/**
 * This is NOT a Global Service
 * It Is a prototype (one instance per injection)
 */
@Injectable()
export class LoginService {
  constructor(private http: HttpClient) {}

  /*
  Request authorization
  Authorization server returns a response with a JWT in header
   */
  login(email, password): Observable<HttpResponse<any>> {
    // POST /api/auth
    return this.http.post(
      "/api/auth/login/",
      { email, password },
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        observe: "response"
      }
    );
  }
}
