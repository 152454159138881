import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ImageDetailsResponse} from "../../../image-upload/models/image-details.response";
import {LightboxModalComponent} from "../../../../shared/components/lightbox/lightbox-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {flatMap} from "rxjs/operators";
import {ImageDetailsService} from "../../../../shared/services/imageDetails/image-details.service";
import {Observable, Subscription} from "rxjs";
import {POINT_OF_INTEREST} from "../../../../shared/models/point-of-interest";
import {BreakpointObserver} from "@angular/cdk/layout";
import {DateFilterModel} from "../../../../shared/models/date-filter.model";

@Component({
  selector: 'iris-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, OnDestroy {
  locationFilter: string;
  dateFilter: DateFilterModel;
  imagesDetails$: Observable<ImageDetailsResponse[]>;
  routeQueryParams$: Subscription;
  locations = POINT_OF_INTEREST;
  routerRef: Router;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    public modal: MatDialog,
    private imageDetailsService: ImageDetailsService,
  ) {
  }

  ngOnInit() {
    this.updatePortfolio();
    this.subscribeToQueryParams();
    this.routerRef = this.router;
  }

  updateDateFilter(startDate: any, endDate: any) {
    this.dateFilter = {startDate, endDate};
  }

  openLightBox(data: { imageId: string, userId: string }) {
    if (!data.imageId && !data.userId) {
      return;
    }
    setTimeout(()=>{this.routeQueryParams$.unsubscribe();},1000);
    const modalRef = this.modal.open(LightboxModalComponent, {
      data: data,
      panelClass: "full-screen-modal",
      backdropClass: "lightbox-background"
    });

    modalRef.afterClosed().subscribe(result => {
      this.resetQueryParams().then(isReset => isReset?this.subscribeToQueryParams():'');
      this.updatePortfolio();
    });
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  updatePortfolio() {
    this.imagesDetails$ = this.route.queryParams.pipe(
      flatMap(params => {
        return this.imageDetailsService.findAllByUser(params['photographer']);
      })
    );
  }

  private resetQueryParams(): Promise<boolean> {
    return this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {image: null},
        queryParamsHandling: 'merge'
      });
  }

  private subscribeToQueryParams(){
    this.routeQueryParams$ = this.route.queryParams.subscribe(params => {
      if (params['image']) {
        this.openLightBox({imageId: params['image'], userId: params['photographer']});
      }
    });
  }

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
  }
}
