import { Pipe, PipeTransform } from '@angular/core';
import {ImageDetailsResponse} from "../../features/image-upload/models/image-details.response";
@Pipe({
  name: 'filterByLocation'
})
export class FilterByLocationPipe implements PipeTransform {
  transform(imageDetails: ImageDetailsResponse[], locationFilter: string): any {
    if (!locationFilter) { return imageDetails; }
    return imageDetails.filter(image => image.location === locationFilter);
  }
}
