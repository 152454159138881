import {Component, OnDestroy, OnInit} from '@angular/core';
import {ImageDetailsService} from "../../../../shared/services/imageDetails/image-details.service";
import { Image} from "@ks89/angular-modal-gallery";
import {ImageDetailsResponse} from "../../../image-upload/models/image-details.response";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {map} from "rxjs/operators";
import {LightboxModalComponent} from "../../../../shared/components/lightbox/lightbox-modal.component";
import {GalleryUtilities} from "../../../../shared/utilities/gallery.utilities";

@Component({
  selector: 'iris-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['./admin-gallery.component.scss']
})
export class AdminGalleryComponent implements OnInit, OnDestroy{

  constructor(
    private imageUploadService: ImageDetailsService,
    public modal: MatDialog
  ) {}
  GalleryUtilities = GalleryUtilities;
  readonly columns: Number = 4;
  images: Image[] = [];
  serverImages: ImageDetailsResponse[];
  imagesByColumn: ImageDetailsResponse[][] = [[],[],[],[]];
  modalCloseSubscription: Subscription;

  ngOnInit() {

    this.imageUploadService
      .findAllRestricted()
      .pipe(
        map(serverImages => {
          this.serverImages = serverImages;
          //TODO delete this
          for(let idx=0; idx < serverImages.length; idx+=4){
            this.imagesByColumn[0].push(serverImages[idx]);
            this.imagesByColumn[1].push(serverImages[idx+1]);
            this.imagesByColumn[2].push(serverImages[idx+2]);
            this.imagesByColumn[3].push(serverImages[idx+3]);
          }
        })
      )
      .subscribe();
  }

  openLightBox(imageId: string) {
    if (!imageId) {
      return;
    }
    const imageDetails = this.serverImages.find(
      imageDetails => imageDetails.id === imageId
    );

    const modalRef = this.modal.open(LightboxModalComponent, {
      data: imageDetails,
      panelClass:"full-screen-modal",
      backdropClass:"lightbox-background"
    });
  }

  ngOnDestroy(): void {
    if (this.modalCloseSubscription) {
      this.modalCloseSubscription.unsubscribe();
    }
  }

}
