import {Component, TemplateRef, ViewChild} from '@angular/core';
@Component({
  selector: 'iris-carousel-image',
  templateUrl: './carousel-image.component.html',
  styleUrls: ['./carousel-image.component.scss']
})
export class CarouselImageComponent{

  // @ts-ignore
  @ViewChild(TemplateRef) template: TemplateRef<any>;
}
