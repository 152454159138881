import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ButtonEvent,
  ButtonsConfig,
  ButtonsStrategy,
  ButtonType,
  Description,
  DescriptionStrategy,
  GridLayout,
  Image, LineLayout,
  PlainGalleryConfig,
  PlainGalleryStrategy
} from "@ks89/angular-modal-gallery";
import { ImageDetailsService } from "../../../../shared/services/imageDetails/image-details.service";
import { map } from "rxjs/operators";
import { ImageDetailsResponse } from "../../../image-upload/models/image-details.response";
import { MatDialog } from "@angular/material";
import { AdminImageInfoModalComponent } from "../../components/image-info-modal/admin-image-info-modal.component";
import { Subscription } from "rxjs";

@Component({
  selector: "iris-admin-grid-gallery",
  templateUrl: "./admin-grid-gallery.component.html",
  styleUrls: ["./admin-grid-gallery.component.scss"]
})
export class AdminGridGalleryComponent implements OnInit, OnDestroy {
  constructor(
    private imageUploadService: ImageDetailsService,
    public modal: MatDialog
  ) {}

  images: Image[] = [];
  imagesDetails: ImageDetailsResponse[];
  modalCloseSubscription: Subscription;
  modalAfterCloseSubscription: Subscription;

  plainGalleryConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.COLUMN,
    layout: new LineLayout({ width: '250px', height: 'auto' }, { length: -1, wrap: true }, 'flex-start')
  };
  buttonsConfig: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [
      {
        className: "fa fa-info-circle white",
        type: ButtonType.CUSTOM,
        // ariaLabel: "custom plus aria label",
        title: "show photograph details",
        fontSize: "40px"
      }
    ]
  };

  customDescription: Description = {
    strategy: DescriptionStrategy.ALWAYS_HIDDEN,
    imageText: "",
    numberSeparator: "/",
    beforeTextDescription: "- Photographer: "
  };

  ngOnInit() {
    this.imageUploadService
      .findAllRestricted()
      .pipe(
        map(serverImages => {
          this.imagesDetails = serverImages;
          return serverImages.map(serverImage => {
            const viewImage: Image = new Image(+serverImage.id,
              // modal image
              {img: serverImage.imageUrl, description: serverImage.userName},
              // thumbnail
              {img: serverImage.imageThumbUrl,size:{width:'250px',height:'auto'}});
            this.images = [...this.images, viewImage];
          });
        })
      )
      .subscribe();
  }

  onButtonAfterHook($event: ButtonEvent) {
    if (!$event || !$event.button) {
      return;
    }
    const imageInfo = this.imagesDetails.find(
      imageDetails => +imageDetails.id === $event.image.id
    );

    const modalRef = this.modal.open(AdminImageInfoModalComponent, {
      width: "50vw",
      data: imageInfo
    });

    /*    this.modalCloseSubscription = modalRef.componentInstance.close.subscribe(() => {
      this.modal.closeAll()
    });
    this.modalAfterCloseSubscription =modalRef.afterClosed().subscribe(result => {
    });*/
  }
  ngOnDestroy(): void {
    if (this.modalCloseSubscription) {
      this.modalCloseSubscription.unsubscribe();
    }
  }
}
