import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs/index';
import {ForgotPasswordService} from '../../services/forgot-password.service';

@Component({
  selector: 'bh-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPassSubscription: Subscription;
  showSpinner = false;
  formGroup: FormGroup;

  constructor(private router: Router,
              private forgotPasswordService: ForgotPasswordService,
              private toastr: ToastrService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  sendMail(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.showSpinner = true;
    const onSuccess = () => {
      this.toastr.success("Please go to your email to reset your password");
      this.showSpinner = false;
    };
    const onError = (response) => {
      if(response.error && response.error.message){
        this.toastr.warning(response.error.message);
      } else if (response.status !== 200) {
        this.toastr.warning("Service unavailable");
      }
      this.showSpinner = false;
    };

    this.forgotPassSubscription = this.forgotPasswordService.sendMail(form.controls.email.value)
    .subscribe(onSuccess, onError);
  }

  ngOnDestroy(): void {
    if (this.forgotPassSubscription) {
      this.forgotPassSubscription.unsubscribe();
    }
  }
}
