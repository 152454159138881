import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class CountryResource {
  private readonly endpoint = "/api/countries";

  constructor(private http: HttpClient) {}

  getNationalities() {
    return this.http.get(`${this.endpoint}/nationalities`);
  }
}
