import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'iris-display',
  templateUrl: './iris-display.component.html',
  styleUrls: ['./iris-display.component.scss']
})
export class IrisDisplayComponent {

  @Input() images: any;
  @Input() showPhotographer = true;
  @Output() onImageClick = new EventEmitter<any>();

  onClick(image: any) {
    this.onImageClick.emit(image);
  }
}
