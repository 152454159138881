import { Component, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CountryService } from "../../../../shared/services/country/country.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ConfirmPasswordService,
  ConfirmPasswordStateMatcher
} from "../../../auth/shared/confirm-password.service";
import { Subscription } from "rxjs";
import { UserState } from "../../../../shared/services/user/user.state";
import { UserDetailsService } from "../../../../shared/services/user/user-details.service";
import { UserModel } from "../../../../shared/models/user.model";
import { ResetPasswordService } from "../../../auth/services/reset-password.service";
import {AuthorizationService} from "../../../../shared/services/authorization/authorization.service";
import {Router} from "@angular/router";

@Component({
  selector: "bh-account",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit, OnDestroy {
  isUserDetailsLoading = false;
  isPasswordChangeLoading = false;
  nationalities;
  infoForm: FormGroup;
  passwordForm: FormGroup;
  matcher = new ConfirmPasswordStateMatcher();
  countrySubscription: Subscription;
  updateDetailsSubscription: Subscription;
  forgotPassSubscription: Subscription;
  user: UserModel;

  constructor(
    private countryService: CountryService,
    private toastr: ToastrService,
    private confirmPasswordService: ConfirmPasswordService,
    private userState: UserState,
    private userDetailsService: UserDetailsService,
    private resetPasswordService: ResetPasswordService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    // get nationalities for dropdown
    this.countrySubscription = this.countryService
      .getNationalities()
      .subscribe(nationalities => (this.nationalities = nationalities));

    // build personal info form
    this.infoForm = this.fb.group({
      fullName: ["", [Validators.required]],
      nationality: ["", [Validators.required]],
      cv: [""]
    });

    // build reset password form
    this.passwordForm = this.fb.group(
      {
        currentPassword: ["", Validators.required],
        password: ["", [Validators.required]],
        confirmPassword: [""]
      },
      { validator: this.confirmPasswordService.checkPasswords }
    );

    // get logged in user
    this.user = this.userState.getUser();

    // patch user details form
    this.infoForm.patchValue(this.user);
  }

  resetInfo(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.isUserDetailsLoading = true;

    // display success message
    const onSuccess = () => {
      this.toastr.success("Your personal info updated successfully.");
      this.userState.clearUser();
      this.isUserDetailsLoading = false;
    };
    //display error message
    const onError = response => {
      if (response.error && response.error.message) {
        this.toastr.warning(response.error.message);
      } else if (response.status !== 200) {
        this.toastr.warning("Service unavailable");
      }
      this.isUserDetailsLoading = false;
    };
    // request to update user details
    this.updateDetailsSubscription = this.userDetailsService
      .updateDetails(this.user.id, {
        fullName: form.controls.fullName.value,
        nationality: form.controls.nationality.value,
        cv:form.controls.cv.value
      })
      .subscribe(onSuccess, onError);
  }

  resetPassword(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    this.isPasswordChangeLoading = true;
    // display success message
    const onSuccess = () => {
      this.toastr.success("Your password updated successfully.");
      this.isPasswordChangeLoading = false;
    };
    // display warning message
    const onError = response => {
      if (response.error && response.error.message) {
        this.toastr.warning(response.error.message);
      } else if (response.status != 200) {
        this.toastr.warning("Service unavailable");
      }
      this.isPasswordChangeLoading = false;
    };
    // request to reset the password
    // TODO create a method to resetPassword with old password instead of token
    // this.forgotPassSubscription = this.resetPasswordService
    //   .restPassword(form.controls.password.value, this.token)
    //   .subscribe(onSuccess, onError);
  }

  logout() {
    this.authorizationService.logout();
    this.router.navigate(["/home"]);

  }

  ngOnDestroy(): void {
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }

    if (this.updateDetailsSubscription) {
      this.updateDetailsSubscription.unsubscribe();
    }
    if (this.forgotPassSubscription) {
      this.forgotPassSubscription.unsubscribe();
    }
  }
}
