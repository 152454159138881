import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialDesignModule} from "./material-design.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {InputFileComponent} from "./components/input-file/input-file.component";
import {RouterModule} from "@angular/router";
import {NumberToArrayPipe} from "./pipe/number-to-array.pipe";
import {IrisDisplayComponent} from "./components/iris-display/iris-display.component";
import {LightboxModalComponent} from "./components/lightbox/lightbox-modal.component";
import {CarouselComponent} from "./components/carousel/carousel.component";
import {CarouselImageComponent} from "./components/carousel/carousel-image/carousel-image.component";
import {FilterByPhotographerPipe} from "./pipe/filter-by-photographer.pipe";
import {FilterByLocationPipe} from "./pipe/filter-by-locations.pipe";
import {AgmCoreModule} from "@agm/core";
import {FilterPhotographersByLocationsPipe} from "./pipe/filter-photographers-by-locations.pipe";
import {FilterPhotographersByDatePipe} from "./pipe/filter-photographers-by-date.pipe";
import {FilterByDatePipe} from "./pipe/filter-by-date.pipe";
import {DragAndDropDirective} from "./directives/drag-and-drop.directive";
import {TranslationComponent} from "./components/translation/translation.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    InputFileComponent,
    TranslationComponent,
    NumberToArrayPipe,
    FilterByLocationPipe,
    FilterByPhotographerPipe,
    FilterPhotographersByLocationsPipe,
    FilterPhotographersByDatePipe,
    FilterByDatePipe,
    IrisDisplayComponent,
    LightboxModalComponent,
    CarouselComponent,
    CarouselImageComponent,
    DragAndDropDirective
  ],
    imports: [
        MaterialDesignModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        RouterModule,
        AgmCoreModule,
        TranslateModule
    ],
  exports: [
    RouterModule,
    MaterialDesignModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InputFileComponent,
    NumberToArrayPipe,
    FilterByLocationPipe,
    FilterByPhotographerPipe,
    FilterPhotographersByLocationsPipe,
    FilterPhotographersByDatePipe,
    FilterByDatePipe,
    IrisDisplayComponent,
    LightboxModalComponent,
    CarouselComponent,
    CarouselImageComponent,
    TranslationComponent,
    DragAndDropDirective
  ]
})
export class SharedModule {
}
