import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { ImageDetailsResponse } from "../../../image-upload/models/image-details.response";
import { ImageDetailsService } from "../../../../shared/services/imageDetails/image-details.service";
import { ToastrService } from "ngx-toastr";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: "iris-lightbox-page",
  templateUrl: "./lightbox-page.component.html",
  styleUrls: ["./lightbox-page.component.scss"]
})
export class LightboxPageComponent implements OnInit {
  @Output() close = new EventEmitter();
  imageDetails: Observable<ImageDetailsResponse>;

  constructor(
    private route: ActivatedRoute,
    private imageDetailsService: ImageDetailsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.imageDetails = this.route.paramMap.pipe(
      switchMap(params => {
        const id = params.get("imageId");
        return this.imageDetailsService.findApprovedImage(id) // http request
      })
    )
  }

  disapprove(id) {
    this.imageDetailsService
      .disapprove(id)
      .subscribe(this.onSuccess, this.onError);
  }

  approve(id) {
    this.imageDetailsService
      .approve(id)
      .subscribe(this.onSuccess, this.onError);
  }

  private onSuccess(data) {
    // this.close.emit();
    this.toastr.info("Image status updated.");
  }
  // display warning
  private onError(response) {
    if (response.status == 401) {
      this.toastr.error("You are not authorized for this action.");
    } else if (response.status == 404) {
      this.toastr.error("Service unavailable");
    } else {
      this.toastr.error("Something went wrong. Trt later.");
    }
  }
}
