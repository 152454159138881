import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { UserState } from "../../../shared/services/user/user.state";
import { map } from "rxjs/operators";
import { AuthorizationService } from "../../../shared/services/authorization/authorization.service";

@Injectable({
  providedIn: "root"
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private userState: UserState,
    private authorizationService: AuthorizationService
  ) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authorizationService.isLoggedIn().pipe(
      map(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate(["login-register"]);
        }
        if (this.userState.getUser().role !== "ADMIN") {
          this.router.navigate(["home"]);
        }
        return isLoggedIn;
      })
    );
  }
}
