import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ImageDetailsRequest} from "../../../features/image-upload/models/image-details.request";
import {FormUtilities} from "../../utilities/form.utilities";
import {ImageDetailsResponse} from "../../../features/image-upload/models/image-details.response";
import {Observable} from "rxjs";
import {UserState} from "../user/user.state";
import {CommentResponse} from "../../models/comment.response";

@Injectable()
export class CommentsService {
  private readonly endpoint = "/api/comments";

  constructor(
    private http: HttpClient,
    private userState: UserState) {
  }

  comment(text: string, imageId: string) {
    // POST /api/comments
    const user = this.userState.getUser();
    if (!user) {
      return
    }
    return this.http.post(`${this.endpoint}`, {text, userId: user.id.toString(), imageId}, {
      observe: "response"
    });
  }
  findAllByImage(imageId: string): Observable<CommentResponse[]> {
    // GET /api/comments
    return this.http.get(`${this.endpoint}?imageId=${imageId}`) as Observable<CommentResponse[]>;
  }

}
