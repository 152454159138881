import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'

@Component({
  selector: 'iris-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss'],
})
export class TranslationComponent implements OnInit {
  @Input() isGreyColor = false;

  private _selected: string;
  private readonly LANG = 'lang';
  private readonly DEFAULT_LANG = 'en';

  get selected() {
    return this._selected
  }

  set selected(value: string) {
    this._selected = value;
    this.translate.use(value)
  }

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
  ) {
  }

  ngOnInit(): void {
    this.selected = this.cookieService.get(this.LANG) || this.DEFAULT_LANG
  }

  useLanguage(language: string) {
    this.cookieService.set(this.LANG, language, null, '/');
    this.selected = language
  }
}
