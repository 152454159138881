import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iris-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  isTabSelected = [true, false, false];


  constructor() { }

  ngOnInit() {
  }

  selectTab(tabId: number){
    this.isTabSelected = [false, false, false];
    this.isTabSelected[tabId] = true;
  }
}
