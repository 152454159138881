import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import { ContactComponent } from './pages/contact/contact.component'
import {AgmCoreModule} from "@agm/core";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ContactComponent],
    imports: [
        SharedModule,
        AgmCoreModule,
        TranslateModule
    ]
})
export class ContactModule { }
