import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material'
import {ImageDetailsResponse} from '../../../image-upload/models/image-details.response'

@Component({
  selector: 'iris-image-info-modal',
  templateUrl: './image-info-modal.component.html',
  styleUrls: ['./image-info-modal.component.css']
})
export class ImageInfoModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ImageInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public imageDetails: ImageDetailsResponse) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
