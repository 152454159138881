import {Component, OnInit} from "@angular/core";
import {ImageDetailsResponse} from "../../../image-upload/models/image-details.response";
import {MatDialog} from "@angular/material";
import {Observable} from "rxjs";
import {POINT_OF_INTEREST} from "../../../../shared/models/point-of-interest";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Router} from "@angular/router";
import {PhotographerService} from "../../../../shared/services/photographer/photographer.service";
import {PhotographerResponse} from "../../../image-upload/models/photographer.response";
import {DateFilterModel} from "../../../../shared/models/date-filter.model";
import {TitleTagService} from "../../../../shared/services/tag/title-tag.service";
import {tap} from "rxjs/operators";
@Component({
  selector: "iris-photographers",
  templateUrl: "./photographers.component.html",
  styleUrls: ["./photographers.component.scss"]
})
export class PhotographersComponent implements OnInit {
  locationFilter: string;
  dateFilter: DateFilterModel;
  photographerFilter: string;
  photographers$: Observable<PhotographerResponse[]>;
  locations = POINT_OF_INTEREST;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private photographerService: PhotographerService,
    public modal: MatDialog,
    private router: Router,
    private titleTagService: TitleTagService
  ) {
  }

  updateDateFilter(startDate: any, endDate: any){
    this.dateFilter = {startDate, endDate};
  }

  ngOnInit() {
    this.photographers$ = this.photographerService.findAll().pipe(
      tap(photographer => this.setMetaTagsForSocialMedia(photographer[0].imageThumbUrl))
    );
  }

  openPortfolio(imageDetails: ImageDetailsResponse){
    this.router.navigate(['/portfolio'], { queryParams: { photographer: imageDetails.userId } });
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  private setMetaTagsForSocialMedia(imageUrl: string){
    this.titleTagService.setTitle(`Iris Green Witness - Photographers`);
    this.titleTagService.setSocialMediaTags(
      `https://www.iris-green-witness.org/photographers`,
      `Iris Green Witness - Photographers`,
      `Iris Green Witness - Photographers`,
      imageUrl);
  }
}
