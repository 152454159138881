import { Pipe, PipeTransform } from '@angular/core';
import {PhotographerResponse} from "../../features/image-upload/models/photographer.response";
import {DateFilterModel} from "../models/date-filter.model";
@Pipe({
  name: 'filterPhotographersByDate'
})
export class FilterPhotographersByDatePipe implements PipeTransform {
  transform(photographers: PhotographerResponse[], dateFilter: DateFilterModel): any {
    if (!dateFilter) { return photographers; }

    return photographers.filter(photographer => this.areDatesInRange(photographer.visitedDates.map(date => new Date(date)), dateFilter));
  }

  private areDatesInRange(dates:Date[], range: DateFilterModel): boolean{
    const filter =  dates.filter(date => this.isDateInRage(date,range));
    return !!filter.length;
  }
  private isDateInRage(date:Date, range: DateFilterModel): boolean{
    if(!date || !(range && range.startDate && range.endDate)){
      return true
    }
    return date.getTime() >= range.startDate.getTime() && date.getTime() <= range.endDate.getTime();
  }
}
