import { NgModule } from "@angular/core";
import { ImageUploadComponent } from "./pages/image-upload/image-upload.component";
import { SharedModule } from "../../shared/shared.module";
import { AgmCoreModule } from "@agm/core";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import {ImageDetailsService} from '../../shared/services/imageDetails/image-details.service'
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ImageUploadComponent],
    imports: [SharedModule, AgmCoreModule, MatGoogleMapsAutocompleteModule, TranslateModule],
  providers:[ImageDetailsService],
  exports: []
})
export class ImageUploadModule {}
