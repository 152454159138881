import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProfileComponent } from "./features/profile/pages/profile/profile.component";
import { LoginComponent } from "./features/auth/pages/login/login.component";
import { ForgotPasswordComponent } from "./features/auth/pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./features/auth/pages/reset-password/reset-password.component";
import { HomeComponent } from "./features/home/home.component";
import { AuthenticatedGuard } from "./core/auth/guards/authenticated.guard";
import { ImageUploadComponent } from "./features/image-upload/pages/image-upload/image-upload.component";
import { PhotographersComponent } from "./features/photographers/page/photographers/photographers.component";
import { AdminGridGalleryComponent } from "./features/admin-image-gallery/pages/grid-gallery/admin-grid-gallery.component";
import { IsAdminGuard } from "./core/auth/guards/is-admin-guard.service";
import { ContactComponent } from "./features/contact/pages/contact/contact.component";
import {LandingComponent} from "./features/landing/landing.component";
import {NavBarComponent} from "./core/header/nav-bar/nav-bar.component";
import {PortfolioComponent} from "./features/photographers/page/portfolio/portfolio.component";
import {AboutComponent} from "./features/about/about.component";
import {SearchComponent} from "./features/search/search.component";

const routes: Routes = [
  { path: "", component: LandingComponent },
  { path: "", component: NavBarComponent,
    children: [
      { path: "home", component: HomeComponent },
      { path: "about", component: AboutComponent },
      { path: "login-register", component: LoginComponent },
      { path: "contact", component: ContactComponent },
      { path: "search", component: SearchComponent },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthenticatedGuard]
      },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "reset-password", component: ResetPasswordComponent },
      {
        path: "image-upload",
        component: ImageUploadComponent,
        canActivate: [AuthenticatedGuard]
      },
      { path: "photographers", component: PhotographersComponent },
      { path: "portfolio", component: PortfolioComponent },
      {
        path: "admin-image-gallery",
        component: AdminGridGalleryComponent,
        canActivate: [IsAdminGuard]
      },
    ]
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
