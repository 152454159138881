import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  forwardRef
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "iris-input-file",
  templateUrl: "./input-file.component.html",
  styleUrls: ["./input-file.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true
    }
  ]
})
export class InputFileComponent implements OnInit {
  /** Link text */
  @Input() text = "Upload";
  /** Name used in form which will be sent in HTTP request. */
  @Input() param = "file";
  /** Target URL for file uploading. */
  /** File extension that accepted, same as 'accept' of <input type="file" />.
   By the default, it's set to 'image/*'. */
  @Input() accept = "image/*";

  @Output() select: EventEmitter<any> = new EventEmitter();
  controlValue: any;
  onChange: Function;
  private file: File | null = null;

  @HostListener("change", ["$event.target.files"])
  emitFiles(event: FileList) {
    this.file = event && event.item(0);
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  ngOnInit() {}

  onImageSelect(event) {
    this.select.emit(event.target.files);
  }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = "";
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}
}
